import { apiUrlBase } from '../../../configuration';
import axios from 'axios';
import { headers } from './base';
import { UserSaved } from '../storage';
import { ScreenPlaylistMedia } from '../../entities/ScreenPlaylistMedia';
import { CreateScreenPlaylistMedia } from '../../entities/CreateScreenPlaylistMedia';
import { objectToFormData } from '../formUtil';
import { ScreenPlaylistSummary } from '../../entities/ScreenPlaylistSummary';
import { ScreenPlaylistDetails } from '../../entities/ScreenPlaylistDetails';
import { CreateScreenPlaylist } from '../../entities/CreateScreenPlaylist';
import { UpdateScreenPlaylist } from '../../entities/UpdateScreenPlaylist';
import { SiteGeoScreenSettingsList } from '../../entities/SiteGeoScreenSettingsList';
import { UpdateSiteGeoScreenSettings } from '../../entities/UpdateSiteGeoScreenSettings';
import { PlaylistScreenSettingsSummary } from '../../entities/PlaylistScreenSettingsSummary';
import { PlaylistScreenSettingsDetails } from '../../entities/PlaylistScreenSettingsDetails';
import { UpdatePlaylistScreenSettings } from '../../entities/UpdatePlaylistScreenSettings';
import { CreatePlaylistScreenSettings } from '../../entities/CreatePlaylistScreenSettings';

export async function listSiteGeoScreenSettings(user: UserSaved): Promise<SiteGeoScreenSettingsList> {
  return axios
    .get(`${apiUrlBase}Screens/sitegeos`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function updateSiteGeoScreenSettings(
  user: UserSaved,
  args: UpdateSiteGeoScreenSettings
): Promise<SiteGeoScreenSettingsList> {
  return axios
    .put(`${apiUrlBase}Screens/sitegeos`, args, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function listPlaylistScreenSettings(
  user: UserSaved,
  search: string
): Promise<PlaylistScreenSettingsSummary[]> {
  return axios
    .get(`${apiUrlBase}Screens/playlist-screens`, {
      headers: headers(user.token),
      params: {
        search,
      },
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getPlaylistScreenSettings(user: UserSaved, id: number): Promise<PlaylistScreenSettingsDetails> {
  return axios
    .get(`${apiUrlBase}Screens/playlist-screens/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function createPlaylistScreenSettings(
  user: UserSaved,
  args: CreatePlaylistScreenSettings
): Promise<PlaylistScreenSettingsDetails> {
  return axios
    .post(`${apiUrlBase}Screens/playlist-screens`, args, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function updatePlaylistScreenSettings(
  user: UserSaved,
  id: number,
  args: UpdatePlaylistScreenSettings
): Promise<PlaylistScreenSettingsDetails> {
  return axios
    .put(`${apiUrlBase}Screens/playlist-screens/${id}`, args, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}
export async function deletePlaylistScreenSettings(user: UserSaved, id: number): Promise<void> {
  return axios
    .delete(`${apiUrlBase}Screens/playlist-screens/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function listScreenPlaylist(
  user: UserSaved,
  search: string | null = null
): Promise<ScreenPlaylistSummary[]> {
  return axios
    .get(`${apiUrlBase}Screens/playlists`, {
      headers: headers(user.token),
      params: {
        search,
      },
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getScreenPlaylist(user: UserSaved, id: number): Promise<ScreenPlaylistDetails> {
  return axios
    .get(`${apiUrlBase}Screens/playlists/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export function createScreenPlaylist(user: UserSaved, args: CreateScreenPlaylist) {
  return axios
    .post(`${apiUrlBase}Screens/playlists`, args, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export function updateScreenPlaylist(user: UserSaved, id: number, args: UpdateScreenPlaylist) {
  return axios
    .put(`${apiUrlBase}Screens/playlists/${id}`, args, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function deleteScreenPlaylist(user: UserSaved, id: number): Promise<boolean> {
  return axios
    .delete(`${apiUrlBase}Screens/playlists/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return true;
      else return false;
    });
}

export async function listScreenMedias(user: UserSaved, search: string | null = null): Promise<ScreenPlaylistMedia[]> {
  return axios
    .get(`${apiUrlBase}Screens/medias`, {
      headers: headers(user.token),
      params: {
        search,
      },
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export function createScreenMedia(user: UserSaved, args: CreateScreenPlaylistMedia) {
  return axios
    .post(`${apiUrlBase}Screens/medias`, objectToFormData(args), {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function deleteScreenMedia(user: UserSaved, id: number): Promise<boolean> {
  return axios
    .delete(`${apiUrlBase}Screens/medias/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return true;
      else return false;
    });
}

export type SiteGeoTVSettingsRequestData = {
  latitude: number | null;
  longitude: number | null;
  rssFeed: string;
  facebookPageUrl: string | null;
  linkedInPageUrl: string | null;
  entrepriseSiteGeoId: number;
};

export type TVSettingsRequestBody = {
  siteGeoScreenSettings: SiteGeoTVSettingsRequestData[];
};

export type SiteGeoTVSettings = {
  latitude: number | null;
  longitude: number | null;
  rssFeed: string;
  facebookPageUrl: string | null;
  linkedInPageUrl: string | null;
  entrepriseSiteGeoId: number;
  salt: string;
};

export type TVSettingsResponseBody = {
  id: number;
  entrepriseId: number;
  siteGeoScreenSettings: SiteGeoTVSettings[];
};

// function createFormData(body: TVSettingsRequestBody) {
//     let formData = new FormData();
//     Object.keys(body).forEach((key) => {
//         formData.append(key, body[key]);
//     });
//     return formData
// }
