import React, { useContext, useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import AppText from '../../components/atomic/AppText';
import { t } from '../../services/translations';
import { PRIMARY_COLOR } from '../../styles/appColor';
import { CategoriesContext } from './CategoriesContext';
import { CategoriesContributions } from '../../utils/Category';
import { AppScreen } from '../../components/containers/AppScreen';
import CreateCategoryForm from '../../components/molecules/CreateCategoryForm';
import useCustomModules from '../../hooks/useCustomModules';

export const categoriesColors: string[] = [
  '#03579b',
  '#0488d1',
  '#03a9f4',
  '#4fc3f7',
  '#253137',
  '#455a64',
  '#607d8b',
  '#90a4ae',
  '#19237e',
  '#303f9f',
  '#3f51b5',
  '#7986cb',
  '#4a198c',
  '#7b21a2',
  '#9c27b0',
  '#ba68c8',
  '#88144f',
  '#c21f5b',
  '#e92663',
  '#f06292',
  '#bf360c',
  '#e64a18',
  '#ff5722',
  '#ff8a65',
  '#f67f17',
  '#fbc02c',
  '#33691d',
  '#689f38',
  '#8bc34a',
];

export function CreateCategoryScreen() {
  const { call } = useContext(CategoriesContext);
  const [success, setSuccess] = useState(false);
  const { customModules } = useCustomModules();

  return (
    <AppScreen>
      <ScrollView style={styles.container}>
        {success && <AppText style={styles.title}>{t('category_creation_successful')}</AppText>}
        {CategoriesContributions.map((categoryContribution) => (
          <CreateCategoryForm
            key={`contribution_${categoryContribution}`}
            contributionType={categoryContribution}
            onSuccess={() => {
              call();
              setSuccess(true);
            }}
          />
        ))}
        {customModules?.map((customModule) => (
          <CreateCategoryForm
            key={`custom_${customModule.identifier}`}
            customModule={customModule}
            onSuccess={() => {
              call();
              setSuccess(true);
            }}
          />
        ))}
      </ScrollView>
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  title: { fontWeight: 'bold', color: PRIMARY_COLOR },
});
