import React from 'react';
import { useQuery } from '@tanstack/react-query';
import useLoggedUser from '../../../hooks/useLoggedUser';
import { ContributionType } from '../../../types';
import { SelectField, SelectFieldProps } from './SelectField';
import { getCategoriesByCustomModuleIdentifier, getCategoriesByType } from '../../../services/api/category.api';

export type CategoryFieldProps = Omit<SelectFieldProps<number>, 'data'> & {
  contributionType?: ContributionType;
  customModuleIdentifier?: string;
};

export default function CategoryField({ contributionType, customModuleIdentifier, ...rest }: CategoryFieldProps) {
  const loggedUser = useLoggedUser();

  const { data: categories } = useQuery(['getCategoriesByType', contributionType], () => {
    if (contributionType) {
      return getCategoriesByType(loggedUser.entreprise?.id!, contributionType, loggedUser);
    } else if (customModuleIdentifier) {
      return getCategoriesByCustomModuleIdentifier(loggedUser.entreprise?.id!, customModuleIdentifier, loggedUser);
    }
  });

  if (!categories || !categories.length) {
    return null;
  }

  return (
    <SelectField {...rest} data={categories?.map((category) => ({ label: category.name, value: category.id })) || []} />
  );
}
