import React from 'react';
import { CategoriesList } from '../containers/CategoriesList';
import { Category } from '../../entities/Category';
import { useQuery } from '@tanstack/react-query';
import { ContributionType } from '../../types';
import { getCategoriesByCustomModuleIdentifier, getCategoriesByType } from '../../services/api/category.api';
import useLoggedUser from '../../hooks/useLoggedUser';

export type CategoryPickerProps = {
  contributionType?: ContributionType;
  customModuleIdentifier?: string;
  onSelect: (category?: Category) => void;
};

export default function CategoryPicker({ contributionType, customModuleIdentifier, onSelect }: CategoryPickerProps) {
  const loggedUser = useLoggedUser();

  const { data: categories } = useQuery(['getCategoriesByType', contributionType], () => {
    if (contributionType) {
      return getCategoriesByType(loggedUser.entreprise?.id!, contributionType, loggedUser);
    } else if (customModuleIdentifier) {
      return getCategoriesByCustomModuleIdentifier(loggedUser.entreprise?.id!, customModuleIdentifier, loggedUser);
    }
  });

  if (!categories || !categories.length) {
    return null;
  }

  return <CategoriesList categories={categories} onSelect={onSelect} />;
}
