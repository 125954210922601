import { Category } from '../entities/Category';
import { t } from '../services/translations';
import { ContributionType } from '../types';

export const CategoriesContributions = [
  ContributionType.COMPANY_NEWS,
  ContributionType.SELL,
  ContributionType.ACTIVITY,
  ContributionType.MUTUAL_AID,
  ContributionType.EVENT,
  ContributionType.FORMATION,
];

export const GLOBAL_CATEGORY: Category = {
  id: -1,
  color: '#45AFE8',
  name: t('global_category'),
  order: -1,
  type: 'all',
  companyId: 0,
};

export const SERVICE_CATEGORY: Category = {
  id: -2,
  color: '#45AFE8',
  name: t('service'),
  order: -1,
  type: 'service',
  companyId: 0,
};

export const GEOSITE_CATEGORY: Category = {
  id: -3,
  color: '#45AFE8',
  name: t('geo_site'),
  order: -1,
  type: 'geo_site',
  companyId: 0,
};

export const hasCategories = (type: ContributionType) => CategoriesContributions.findIndex((c) => c === type) >= 0;
