import * as React from 'react';
import { View, ViewStyle, StyleSheet } from 'react-native';
import AppText from './AppText';

export function Label(props: { icon?: React.ReactNode; text: string; color?: string; style?: ViewStyle }) {
  return (
    <View
      style={[styles.container, { backgroundColor: props.color === undefined ? '#4FDFC4' : props.color }, props.style]}
    >
      <AppText style={styles.text}>
        {props.icon ? <View style={styles.icon}>{props.icon}</View> : null}
        {props.text}
      </AppText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 80,
  },
  text: {
    color: 'white',
    flex: 0,
  },
  icon: {
    marginRight: 8,
  },
});
