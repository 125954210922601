import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FAB } from '@rneui/themed';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import usePaginatedList from '../../../../hooks/usePaginatedList';
import { listCustomModuleContributions } from '../../../../services/api/custom.module.api';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { SearchTextInput } from '../../../../components/atomic/SearchTextInput';
import { ResponsiveFlatList } from '../../../../components/atomic/ResponsiveFlatList';
import { StyleSheet } from 'react-native';
import { ContributionPreviewItem } from '../../../../components/atomic/ContributionItemSquare';
import { getPictureURI } from '../../../../services/api/helper.api';
import { newsImage } from '../../../../services/assets';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { ScreenNames } from '../../../../ScreenNames';
import { LIGHT_GRAY, PRIMARY_COLOR } from '../../../../styles/appColor';
import { isAdmin, isMobile, isSubAdmin } from '../../../../services/utils';
import { setLoading } from '../../../../store/action';
import AppText from '../../../../components/atomic/AppText';
import { t } from '../../../../services/translations';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import useContributionListRefresh from '../../../../hooks/useContributionListRefresh';
import CategoryPicker from '../../../../components/atomic/CategoryPicker';
import { Category } from '../../../../entities/Category';

export type ListCustomModuleContributionScreenProps = {
  route: {
    params: {
      moduleIdentifier: string;
    };
  };
};

export default function ListCustomModuleContributionScreen({ route }: ListCustomModuleContributionScreenProps) {
  const user = useLoggedUser();
  const moduleIdentifier = route.params.moduleIdentifier;
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();
  const hasWriteAccess = isAdmin(user) || isSubAdmin(user);

  const [search, setSearch] = useState('');
  const [filterCategory, setFilterCategory] = useState<undefined | Category>(undefined);

  const {
    data: contributions,
    isLoading,
    fetchNext,
    refresh,
  } = usePaginatedList(
    (cursor: string | null) =>
      listCustomModuleContributions(user, moduleIdentifier, cursor, search, {
        categoryId: filterCategory?.id,
      }),
    [search, moduleIdentifier, user, filterCategory?.id]
  );

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Refresh after an update
  useContributionListRefresh(refresh);

  return (
    <AppScreen>
      <SearchTextInput
        onPressIcon={() => setSearch('')}
        leftIcon="arrow-back"
        onChangeText={setSearch}
        value={search}
        style={styles.search}
      />
      {moduleIdentifier && <CategoryPicker customModuleIdentifier={moduleIdentifier} onSelect={setFilterCategory} />}
      <ResponsiveFlatList
        data={contributions}
        numColumns={isMobile() ? 2 : undefined}
        renderItem={({ item }) => {
          return (
            <ContributionPreviewItem
              title={item.title}
              imageURI={item.imageId ? getPictureURI(item.imageId) : newsImage}
              videoUrl={item.videoUrl}
              onPress={() => {
                navigation.navigate(ScreenNames.CustomModuleDetails, {
                  id: item.id,
                });
              }}
              overlayContent={<></>}
              additionalButtons={<></>}
              labelContent={<></>}
            />
          );
        }}
        ListEmptyComponent={<AppText style={styles.emptyText}>{t('no_contributions')}</AppText>}
        onEndReached={fetchNext}
      />
      {hasWriteAccess && (
        <FAB
          color={PRIMARY_COLOR}
          icon={<FontAwesome6 size={15} name="plus" color="#fff" />}
          onPress={() => {
            navigation.navigate(ScreenNames.CustomModuleCreate);
          }}
          placement="left"
        />
      )}
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  search: { marginBottom: 10 },
  emptyText: {
    textAlign: 'center',
    color: LIGHT_GRAY,
  },
});
