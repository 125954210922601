import axios from 'axios';
import { apiUrlBase } from '../../../configuration';
import { headers } from './base';
import { Category } from '../../entities/Category';
import { ContributionType, ContributionTypesInfos } from '../../types';
import { UserSaved } from '../storage';

export const getCategories = (companyId: number | string, token: string): Promise<{ [key: string]: Category[] }> =>
  axios
    .get(apiUrlBase + `categories/${companyId}`, {
      headers: headers(token),
    })
    .then((r) => r.data);

export async function getCategoriesByType(
  companyId: number | string,
  type: ContributionType,
  user: UserSaved
): Promise<Category[]> {
  const result = await axios
    .get(apiUrlBase + `categories/${companyId}/${ContributionTypesInfos[type].key}`, {
      headers: headers(user.token),
    })
    .then((r) => r.data as Category[]);

  if (type === ContributionType.COMPANY_NEWS) {
    return [...result];
  } else {
    return result;
  }
}

export async function getCategoriesByCustomModuleIdentifier(
  companyId: number | string,
  customModuleIdentifier: string,
  user: UserSaved
): Promise<Category[]> {
  const result = await axios
    .get(apiUrlBase + `categories/${companyId}/by_custom_module/${customModuleIdentifier}`, {
      headers: headers(user.token),
    })
    .then((r) => r.data as Category[]);

  return result;
}

export interface PostCategory {
  type?: string | null;
  customModuleId?: number | null;
  name: string;
  color: string;
}

export const postCategory = (companyId: number | string, category: PostCategory, token: string): Promise<Response> =>
  axios.post(`${apiUrlBase}categories/${companyId}`, category, {
    headers: headers(token),
  });

export const postOrderCategory = (orders: { [key: string]: number }, token: string): Promise<Response> =>
  axios.post(`${apiUrlBase}categories`, orders, {
    headers: headers(token),
  });

export interface PutCategory {
  name: string;
  color: string;
}

export const putCategory = (id: number, category: PutCategory, token: string): Promise<Response> =>
  axios.put(`${apiUrlBase}categories/${id}`, category, {
    headers: headers(token),
  });

export const deleteCategory = (id: number, token: string): Promise<Response> =>
  axios.delete(`${apiUrlBase}categories/${id}`, {
    headers: headers(token),
  });
