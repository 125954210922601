import React from 'react';
import { useDispatch } from 'react-redux';
import FormLoading from '../../../../components/containers/FormLoading';
import { CompanyNewsUpload } from '../../../../models/CompanyNews';
import { postCompanyNews } from '../../../../services/api/news.contributions.api';
import { t } from '../../../../services/translations';
import {
  alertInfo,
  hasSubAdminRight,
  isAdmin,
  PickPhotoOrDocumentResult,
  sendSuccessText,
} from '../../../../services/utils';
import { setContributionsListNeedsRefresh, setLoading } from '../../../../store/action';
import { ContributionType } from '../../../../types';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import NewsFormFields, { schema } from './NewsFormFields';
import Form from '../../../../components/atomic/formik/Form';
import { useMutation } from '@tanstack/react-query';
import { newsImage } from '../../../../services/assets';
import { NavigationProp, useNavigation } from '@react-navigation/native';

export default function CreateNewsScreen() {
  const dispatch = useDispatch();
  const user = useLoggedUser();
  const navigation = useNavigation<NavigationProp<any>>();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async (body: CompanyNewsUpload) => {
      dispatch(setLoading(true));
      return postCompanyNews(user, body, (body.image as PickPhotoOrDocumentResult | null) || undefined);
    },
    onSuccess: (_, values) => {
      const hasRights =
        isAdmin(user) ||
        hasSubAdminRight(user, values.entrepriseServiceIds, values.entrepriseSiteGeoIds, values.entrepriseGroupIds);

      alertInfo(sendSuccessText(user, ContributionType.COMPANY_NEWS, hasRights));
      dispatch(setContributionsListNeedsRefresh(true));
      navigation.goBack();
    },
    onError: () => {
      alertInfo(t('error_occurred'));
    },
    onSettled: () => {
      dispatch(setLoading(false));
    },
  });

  if (isLoading) {
    return <FormLoading />;
  }

  return (
    <Form
      initialValues={{
        entrepriseId: user.entrepriseId,
        userId: user.id,
        translations: [],
        titre: '',
        texte: '',
        highlightedDays: null,
        commentsActivated: true,
        notifActivated: true,
        emailActivated: false,
        publicationMode: null,
      }}
      onSubmit={mutateAsync as any}
      validationSchema={schema}
      validateOnMount
    >
      <NewsFormFields defaultPhoto={newsImage} />
    </Form>
  );
}
