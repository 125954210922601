import { Dimensions, Image, ImageBackground, StyleSheet, TouchableOpacity, View } from 'react-native';
import AppText, { Mode } from './AppText';
import { setContributionsListNeedsRefresh, setLoading } from '../../store/action';
import { dateLocale, t } from '../../services/translations';
import React, { ReactNode, useMemo } from 'react';
import {
  extractContributionFromResponse,
  getDescriptionByType,
  getTitleByType,
  resolveContributionType,
} from '../../services/api/contributions';
import * as Localization from 'expo-localization';
import { apiTimeZone } from '../../../configuration';
import { ContributionType } from '../../types';
import { PRIMARY_COLOR, SECONDARY_COLOR, WHITE } from '../../styles/appColor';
import { alertInfo, combineNames, getReadableDate, hasSubAdminRight, isAdmin, isMobile } from '../../services/utils';
import { ProfilePicture } from './ProfilePicture';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';
import { UserSubRow } from './UserSubRow';
import { LinearGradient } from 'expo-linear-gradient';
import { useDispatch } from 'react-redux';
import { AppButton } from './AppButton';
import { getContributionImageSource, isAnonymous } from '../../services/api/helper.api';
import { TwoActionsGenericPopup } from '../containers/TwoActionsButtonPopup';
import { ImageWithFooterText } from './ImageWithFooterText';
import { ContributionLabel } from './ContributionLabel';
import { removeCompanyNews, setApproveNews } from '../../services/api/news.contributions.api';
import { GetContributionsMode } from '../../services/api/getContributionsMode';
import { ScreenNames } from '../../ScreenNames';
import { EditButton } from './EditButton';
import { RemoveButton } from './RemoveButton';
import { GetMediaInfo } from '../../utils/Medias';
import SpreadingChipList from './SpreadingChipsList';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import useLoggedUser from '../../hooks/useLoggedUser';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import Octicons from 'react-native-vector-icons/Octicons';
import { Label } from './Label';
import { format } from 'date-fns';

function RemoveButtonCompanyNews(props: { item; user; dispatch }) {
  return (
    <TwoActionsGenericPopup
      title={t('wish_to_remove')}
      button1Title={t('yes')}
      button2Title={t('no')}
      onPressButton1={() => {
        props.dispatch(setLoading(true));
        removeCompanyNews(props.user, props.item.id)
          .then(() => alertInfo(t('removed_ok')))
          .finally(() => {
            props.dispatch(setContributionsListNeedsRefresh(true));
            props.dispatch(setLoading(false));
          });
      }}
      onPressButton2={() => {}}
    >
      <FontAwesome6 size={18} name="trash-alt" color={PRIMARY_COLOR} />
    </TwoActionsGenericPopup>
  );
}

export function SquareTouchableWrapper(props: { children: ReactNode; numColumns?: number; onPress: () => void }) {
  return (
    <TouchableOpacity
      style={{
        flex: 1,
        marginLeft: props.numColumns !== 1 ? 5 : 0,
        marginRight: props.numColumns !== 1 ? 5 : 0,
      }}
      onPress={props.onPress}
    >
      {props.children}
    </TouchableOpacity>
  );
}

export function ContributionItemSquare(
  item: any,
  getContributionsMode: GetContributionsMode,
  navigation,
  user,
  contributionType: ContributionType,
  onPress
) {
  return (
    <ContributionPreviewItem
      additionalButtons={
        getContributionsMode === GetContributionsMode.mine ? (
          <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>
            {/*{contributionType != ContributionType.SURVEY && (*/}
            <EditButton contributionType={contributionType} item={item} navigation={navigation} />
            {/*)}*/}
            <RemoveButton item={item} user={user} />
          </View>
        ) : null
      }
      labelContent={
        isMobile() || contributionType !== ContributionType.ALL ? null : (
          <ContributionLabel contributionType={contributionType} />
        )
      }
      title={getTitleByType(item, contributionType)}
      overlayContent={
        !isMobile() && (
          <>
            {!isAnonymous(item, contributionType) && (
              <UserSubRow
                user={item.contribution.userIdCreateurNavigation}
                withPicture={true}
                style={{ flex: 1 }}
                textColor="white"
              />
            )}
            <AppText
              style={{
                fontSize: 12,
                fontStyle: 'italic',
                color: 'white',
                paddingRight: 7,
              }}
            >
              {getReadableDate(item.visibleStartDate ? item.visibleStartDate : item.contribution.dateCreation, true)}
            </AppText>
          </>
        )
      }
      imageURI={getContributionImageSource(item.fichierIdPhoto, contributionType)}
      videoUrl={item?.videoUrl}
      onPress={onPress}
    />
  );
}

export type ContributionPreviewItemProps = {
  additionalButtons: any;
  labelContent: any;
  title: any;
  overlayContent: any;
  imageURI: any;
  onPress: () => void;
  style?: any;
  allImage?: boolean;
  videoUrl?: string | null | undefined;
};

export function ContributionPreviewItem(props: ContributionPreviewItemProps) {
  const mediaLogo = useMemo(() => (props.videoUrl ? GetMediaInfo(props.videoUrl)?.logo : undefined), [props.videoUrl]);

  return (
    <View style={[{ paddingBottom: 18 }, props.style]}>
      <SquareTouchableWrapper onPress={props.onPress} style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <ImageBackground
            style={{
              width: '100%',
              height: isMobile()
                ? props.allImage
                  ? Dimensions.get('window').width - 50
                  : Dimensions.get('window').width / 2 - 30
                : 290,
              overflow: 'hidden',
              borderRadius: 20,
              marginBottom: 10,
            }}
            imageStyle={{ borderRadius: 20, resizeMode: 'cover' }}
            source={props.imageURI}
          >
            <View style={{ flex: 1 }}></View>
            <LinearGradient
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                //flex: 0,
                padding: 5,
                height: 35,
              }}
              start={{ x: 0.0, y: 2 }}
              end={{ x: 0, y: 0 }}
              colors={['rgb(0,0,0)', '#00000000']}
            >
              {props.overlayContent}
            </LinearGradient>
          </ImageBackground>

          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              alignItems: 'stretch',
              marginBottom: 9,

              maxWidth: '100%',
            }}
          >
            <AppText style={{ fontSize: 16, flex: 1 }} mode={Mode.BOLD} numberOfLines={1}>
              {props.title}
            </AppText>
            <View>{props.labelContent}</View>
          </View>
        </View>
        {!!mediaLogo && <Image source={mediaLogo} style={{ position: 'absolute', width: 50, height: 50, right: 10 }} />}
      </SquareTouchableWrapper>
      {props.additionalButtons}
    </View>
  );
}

export function ContributionItemRowBigPicture(props: {
  item;
  getContributionsMode: GetContributionsMode;
  navigation;
  user;
  contributionType: ContributionType;
  isAdminView?: boolean;
  onPress;
  style?;
}) {
  let author = {};
  let date = '';
  const item = props.item;
  if (item.contribution !== undefined) {
    author = item.contribution.userIdCreateurNavigation;
    date = item.visibleStartDate ? item.visibleStartDate : item.contribution.dateCreation;
  } else {
    author = item.userIdCreateurNavigation;
    date = item.visibleStartDate ? item.visibleStartDate : item.dateCreation;
  }
  let _contributionType = props.contributionType;
  let allImage = _contributionType === ContributionType.ALL;
  if (_contributionType === ContributionType.ALL) {
    _contributionType = resolveContributionType(item);
  }
  /**
   * As the response of the API is inconsistent, it is sometimes impossible to determine the type of contribution.
   * Once the API has been updated, the function resolveContributionType should be updated too.
   */
  if (_contributionType === ContributionType.UNKNOWN) return <></>;

  const typedData = extractContributionFromResponse(item, _contributionType);

  return (
    <ContributionPreviewItem
      additionalButtons={
        props.isAdminView && isAdmin(props.user) ? (
          <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>
            <EditButton contributionType={_contributionType} item={item} navigation={props.navigation} />
            <RemoveButton item={typedData} user={props.user} />
          </View>
        ) : null
      }
      labelContent={<ContributionLabel contributionType={_contributionType} />}
      title={getTitleByType(item, _contributionType)}
      videoUrl={typedData?.videoUrl}
      style={props.style}
      allImage={allImage}
      overlayContent={
        <>
          {!isAnonymous(item, _contributionType) && (
            <UserSubRow user={author} withPicture={true} style={{ flex: 1 }} textColor="white" />
          )}
          <AppText
            style={{
              fontSize: 12,
              fontStyle: 'italic',
              color: 'white',
              paddingRight: 7,
            }}
          >
            {getReadableDate(date, true)}
          </AppText>
        </>
      }
      onPress={props.onPress}
      imageURI={getContributionImageSource(typedData?.fichierIdPhoto, _contributionType)}
    />
  );
}

export function ContributionItemRowCompanyNews(props: { item; navigation; isAdminView: boolean; onPress; style? }) {
  const user = useLoggedUser();
  const author = props.item.userIdCreateurNavigation
    ? props.item.userIdCreateurNavigation
    : props.item.mActu.userIdCreateurNavigation;
  const locale = dateLocale();

  const dispatch = useDispatch();

  const isApproved = props.item.lovIdEtat === 39;

  function _approveNews() {
    dispatch(setLoading(true));
    setApproveNews(user, props.item.id)
      .then((response) => {
        dispatch(setContributionsListNeedsRefresh(true));
      })
      .catch((e) => {
        alertInfo(t('error_occurred'));
      })
      .finally(() => {
        dispatch(setLoading(false));
        props.navigation.navigate(ScreenNames.LoggedInDrawerNavigation, {
          screen: ScreenNames.LoggedInHome,
          params: { screen: ScreenNames.CompanyNewsScreen, params: { screen: ScreenNames.ContributionListScreen } },
        });
      });
  }

  function _disapproveNews() {
    dispatch(setLoading(true));
    removeCompanyNews(user, props.item.id)
      .then(() => alertInfo(t('removed_ok')))
      .finally(() => {
        dispatch(setContributionsListNeedsRefresh(true));
        dispatch(setLoading(false));
      });
  }

  const mediaLogo = useMemo(() => {
    if (!props.item.videoUrl) return undefined;
    return GetMediaInfo(props.item.videoUrl)?.logo;
  }, [props.item]);

  const publicationDate =
    props?.item?.visibleStartDate?.split('.')[0] !== props?.item?.mActu?.dateCreation?.split('.')[0]
      ? (props?.item?.visibleStartDate as string | undefined)
      : undefined;

  return (
    <View style={[{ marginBottom: 30 }, props.style]}>
      <SquareTouchableWrapper onPress={props.onPress}>
        <ImageWithFooterText
          imageSource={getContributionImageSource(props.item.imageId, ContributionType.COMPANY_NEWS, props.item)}
          author={author}
          date={props.item.visibleStartDate ? props.item.visibleStartDate : props.item.dateCreation}
          style={
            !props.isAdminView && props?.item?.highlighted
              ? { borderWidth: 3, borderColor: SECONDARY_COLOR }
              : undefined
          }
          header={
            props.isAdminView ? (
              <View style={{ padding: 8, gap: 4, flexDirection: 'row', flexWrap: 'wrap' }}>
                <SpreadingChipList
                  item={props.item.mActu || props.item.contribution || props.item}
                  chipStyle={{ margin: 0 }}
                  inline
                />
                {props.item.validator && isApproved && (
                  <Label
                    icon={<FontAwesome6 name="check" size={16} color={WHITE} />}
                    text={`${props.item.validator.prenom} ${props.item.validator.nom}`}
                    color={PRIMARY_COLOR}
                  />
                )}
                {props.item.dateValidation && isApproved && (
                  <Label
                    icon={<FontAwesome6 name="calendar-check" size={16} color={WHITE} />}
                    text={format(new Date(props.item.dateValidation), 'dd/MM/yyyy', { locale })}
                    color={PRIMARY_COLOR}
                  />
                )}
              </View>
            ) : props?.item?.highlighted ? (
              <View style={{ alignItems: 'flex-start', padding: 8 }}>
                <Label text={t('highlighted')} color={SECONDARY_COLOR} />
              </View>
            ) : null
          }
        />
        {!!mediaLogo && <Image source={mediaLogo} style={{ position: 'absolute', width: 50, height: 50, right: 10 }} />}
        <View style={{ marginTop: 10 }}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <AppText
              numberOfLines={1}
              mode={Mode.BOLD}
              style={{ fontSize: 16, lineHeight: 19, marginBottom: 9, flex: 1 }}
            >
              {getTitleByType(props.item, ContributionType.COMPANY_NEWS)}
            </AppText>
          </View>
          <AppText
            numberOfLines={3}
            style={{
              width: '100%',
              fontSize: 14,
              lineHeight: 16,
              fontFamily: FONT_FAMILY_DEFAULT.regular,
            }}
          >
            {getDescriptionByType(props.item, ContributionType.COMPANY_NEWS)}
          </AppText>
        </View>
      </SquareTouchableWrapper>
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {!!props.isAdminView && (
          <>
            {!isApproved && (
              <AppButton
                onPress={_approveNews}
                title={t('approve')}
                style={{ marginTop: 5, marginBottom: 5, width: 130 }}
                textStyle={{ fontSize: 16 }}
              />
            )}
            {isAdmin(user) ||
            hasSubAdminRight(
              user,
              props.item.mActu.entrepriseServices.map((e) => e.entrepriseServiceId),
              props.item.mActu.entrepriseSiteGeos.map((e) => e.entrepriseSiteGeoId),
              props.item.mActu.entrepriseGroups.map((e) => e.entrepriseGroupId)
            ) ? (
              <View
                style={{ flexDirection: 'row', justifyContent: 'space-around', flex: 1, marginTop: 5, marginBottom: 5 }}
              >
                <EditButton
                  contributionType={ContributionType.COMPANY_NEWS}
                  item={props.item}
                  navigation={props.navigation}
                />
                {isApproved && (
                  <TouchableOpacity
                    onPress={() => {
                      props.navigation.navigate(ScreenNames.LoggedInDrawerNavigation, {
                        screen: ScreenNames.DuplicateCompanyNews,
                        params: {
                          id: props.item.id,
                          action: 'duplicate',
                        },
                      });
                    }}
                  >
                    <Octicons size={18} name="duplicate" color={PRIMARY_COLOR} />
                  </TouchableOpacity>
                )}
                {isApproved && <RemoveButtonCompanyNews item={props.item} user={user} dispatch={dispatch} />}
              </View>
            ) : null}
            {!isApproved && (
              <AppButton
                onPress={_disapproveNews}
                title={t('disapprove')}
                style={{
                  width: 130,
                }}
                textStyle={{ fontSize: 16 }}
              />
            )}
            {!isApproved && (
              <View
                style={{
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginVertical: 10,
                }}
              >
                <AppText numberOfLines={1} mode={Mode.BOLD} style={{ fontSize: 16, flex: 1, color: PRIMARY_COLOR }}>
                  {t('publication_mode')}
                </AppText>
                <AppText numberOfLines={1} style={{ fontSize: 14, flex: 1 }}>
                  {!publicationDate
                    ? t('immediate_posting')
                    : formatInTimeZone(
                        zonedTimeToUtc(publicationDate, apiTimeZone),
                        Localization.timezone,
                        'd MMMM yyyy HH:mm',
                        {
                          locale,
                        }
                      )}
                </AppText>
              </View>
            )}
          </>
        )}
      </View>
    </View>
  );
}

export function ContributionItemRowCarPool({
  item,
  getContributionsMode,
  navigation,
  user,
  contributionType,
  onPress,
}) {
  const author = item.contribution.userIdCreateurNavigation;
  const permanent = item.lovIdType === 22;
  return (
    <View style={[style.carPoolRow, permanent ? style.permanentRow : style.temporaryRow]}>
      <SquareTouchableWrapper onPress={onPress}>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ alignItems: 'center', marginRight: 5, width: 10 }}>
            <View
              style={{
                backgroundColor: '#555',
                borderRadius: 120 / 5,
                height: 10,
                width: 10,
              }}
            />
            <View style={{ backgroundColor: '#555', height: 15, width: 1 }} />
            <View
              style={{
                borderWidth: 1,
                borderRadius: 120 / 5,
                height: 10,
                width: 10,
              }}
            />
          </View>
          <View style={{ flex: 2 }}>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 9 }}>
              <AppText mode={Mode.BOLD} numberOfLines={1}>
                {item.libelleDepart}
              </AppText>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 9 }}>
              <AppText mode={Mode.BOLD} numberOfLines={1}>
                {item.libelleArrivee}
              </AppText>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <ProfilePicture pictureId={author.fichierIdProfil} />
              <View style={{ flex: 1, marginLeft: 10 }}>
                <AppText>{combineNames(author)}</AppText>
              </View>
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <AppText
              style={{
                color: permanent ? '#45AFE8' : '#4FDFC4',
                textAlign: 'right',
              }}
            >
              {permanent ? t('permanent') : t('temporary')}
            </AppText>
          </View>
        </View>
      </SquareTouchableWrapper>
      {getContributionsMode === GetContributionsMode.mine ? (
        <View style={{ flex: 0, marginLeft: 5, alignItems: 'center', justifyContent: 'space-around' }}>
          <EditButton item={item} contributionType={contributionType} navigation={navigation} />
          <RemoveButton item={item} user={user} />
        </View>
      ) : null}
    </View>
  );
}

const style = StyleSheet.create({
  carPoolRow: {
    borderRadius: 8,
    marginBottom: 24,
    padding: 24,
    flexDirection: 'row',
  },
  permanentRow: {
    backgroundColor: '#4FDFC41A',
  },
  temporaryRow: {
    backgroundColor: '#45AFE81A',
  },
});
