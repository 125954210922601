import axios from 'axios';
import { apiUrlBase } from '../../../configuration';
import { headers } from './base';
import { UserSaved } from '../storage';
import { CustomModule } from '../../entities/CustomModule';
import { Paginated } from '../../entities/Paginated';
import { ContributionCustomModule } from '../../entities/ContributionCustomModule';
import { ContributionCustomModuleCreate } from '../../entities/ContributionCustomModuleCreate';
import { ContributionCustomModuleUpdate } from '../../entities/ContributionCustomModuleUpdate';
import { objectToFormData } from '../formUtil';

export async function listCustomModules(user: UserSaved): Promise<CustomModule[]> {
  return axios
    .get(`${apiUrlBase}CustomModule`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getModuleDetails(user: UserSaved, moduleIdentifier: string): Promise<CustomModule> {
  return axios
    .get(`${apiUrlBase}CustomModule/${moduleIdentifier}/details`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export type GetCustomModuleContributionsListOptions = {
  categoryId?: number;
};

export async function listCustomModuleContributions(
  user: UserSaved,
  moduleIdentifier: string,
  cursor: string | null,
  search?: string | null,
  options: GetCustomModuleContributionsListOptions = {}
): Promise<Paginated<ContributionCustomModule, string>> {
  return axios
    .get(`${apiUrlBase}CustomModule/${moduleIdentifier}`, {
      headers: headers(user.token),
      params: { ...options, cursor, search },
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function listCustomModuleContributionsAdmin(
  user: UserSaved,
  moduleIdentifier: string,
  cursor: string | null,
  search?: string | null
): Promise<Paginated<ContributionCustomModule, string>> {
  return axios
    .get(`${apiUrlBase}CustomModule/${moduleIdentifier}/admin`, {
      headers: headers(user.token),
      params: { cursor, search },
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getCustomModuleContributionsDetails(
  user: UserSaved,
  id: number
): Promise<ContributionCustomModule> {
  return axios
    .get(`${apiUrlBase}CustomModule/contribution/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export function createCustomModuleContributions(
  user: UserSaved,
  moduleIdentifier: string,
  args: ContributionCustomModuleCreate
) {
  return axios
    .post(`${apiUrlBase}CustomModule/${moduleIdentifier}`, objectToFormData(args), {
      headers: headers(user.token, 'multipart/form-data'),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export function updateCustomModuleContributions(user: UserSaved, id: number, args: ContributionCustomModuleUpdate) {
  return axios
    .put(`${apiUrlBase}CustomModule/contribution/${id}`, objectToFormData(args), {
      headers: headers(user.token, 'multipart/form-data'),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}
