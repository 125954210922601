import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import store from './src/store/store';
import { Provider } from 'react-redux';
import Main from './src/Main';
import LoginAs from './src/LoginAs';
import 'expo-splash-screen';
import * as NavigationBar from 'expo-navigation-bar';
import { EventProvider } from 'react-native-outside-press';
import 'intl-pluralrules';
import { retrievedSavedLanguage } from './src/services/storage';
import { changeLanguage, t } from './src/services/translations';
import { NavigationContainer } from '@react-navigation/native';
import { isMobile, navigationRef } from './src/services/utils';
import { createStackNavigator } from '@react-navigation/stack';
import { fetchFonts } from './FetchFonts';
import { ScreenNames } from './src/ScreenNames';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import Ignore from './src/utils/Ignore';
import { StatusBar } from 'react-native';
import { PdfProvider } from './src/components/atomic/Pdf';
import BackIcon from './src/components/atomic/BackIcon';
import { linkingPrefixes } from './configuration';
import PushNotificationProvider from './src/components/atomic/PushNotificationProvider';

const queryClient = new QueryClient();
const Stack = createStackNavigator();

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: true,
  }),
});

Ignore();

if (isMobile() && Constants.appOwnership !== 'expo') {
  const ScreenOrientation = require('expo-screen-orientation');
  const { OrientationLock } = ScreenOrientation;
  const { isTablet } = require('react-native-device-info');

  if (!isTablet()) {
    ScreenOrientation.lockAsync(OrientationLock.PORTRAIT_UP);
  } else {
    ScreenOrientation.unlockAsync();
  }
}

export function AppProviders({ children }: { children: React.ReactNode }) {
  useMemo(() => {
    retrievedSavedLanguage().then(changeLanguage);
  }, []);

  return (
    <Provider store={store}>
      <PushNotificationProvider>
        <PdfProvider>
          <StatusBar backgroundColor="#fff" barStyle="dark-content" />
          <EventProvider>{children}</EventProvider>
        </PdfProvider>
      </PushNotificationProvider>
    </Provider>
  );
}

export default function App() {
  const [dataLoaded, setDataLoaded] = useState(true);

  useEffect(() => {
    Promise.all([fetchFonts()]).then(() => {
      setDataLoaded(true);
    });

    NavigationBar.setBackgroundColorAsync('white');
    NavigationBar.setButtonStyleAsync('light');
  }, []);

  if (!dataLoaded) {
    return null;
  }

  const linking = {
    prefixes: linkingPrefixes,
  };

  return (
    <QueryClientProvider client={queryClient}>
      <AppProviders>
        <NavigationContainer ref={navigationRef} linking={linking}>
          <Stack.Navigator screenOptions={{ headerBackImage: BackIcon }}>
            <Stack.Screen name={ScreenNames.Main} component={Main} options={{ headerShown: false }} />
            <Stack.Screen name={ScreenNames.LoginAs} component={LoginAs} options={{ headerShown: false }} />
          </Stack.Navigator>
        </NavigationContainer>
      </AppProviders>
    </QueryClientProvider>
  );
}
