import React from 'react';
import { FormField } from '../FormField';
import { t } from '../../../services/translations';
import { isMobile } from '../../../services/utils';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useField } from 'formik';
import TextField from './TextField';

export type ColorPickerProps = {
  name: string;
  title: string;
  colors: string[];
  required?: boolean;
  style?: any;
};

export default function ColorPickerField({ name, title, colors, required, style }: ColorPickerProps) {
  const [field, , helper] = useField(name);

  return (
    <FormField title={title + (required ? '*' : '')} style={[styles.container, style]}>
      <View style={styles.content}>
        {colors.map((color) => (
          <TouchableOpacity
            key={color}
            style={[
              styles.color,
              field.value === color ? styles.colorSelected : {},
              {
                backgroundColor: color,
              },
            ]}
            onPress={() => helper.setValue(color)}
          />
        ))}
        {field.value && CSS.supports('color', field.value) && !colors.includes(field.value) && (
          <View
            style={[
              styles.color,
              styles.colorSelected,
              {
                backgroundColor: field.value,
              },
            ]}
          />
        )}
      </View>
      {!isMobile() && <TextField name={name} style={styles.customColorField} title={t('custom_color')} />}
    </FormField>
  );
}

const styles = StyleSheet.create({
  container: { marginBottom: 48 },
  content: { flexDirection: 'row', flexWrap: 'wrap', maxWidth: !isMobile() ? '70%' : undefined },
  color: {
    width: 64,
    height: 24,
    borderColor: 'black',
    borderWidth: 0,
  },
  colorSelected: {
    borderWidth: 2,
  },
  customColorField: { marginTop: 16 },
});
