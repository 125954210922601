import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import * as Yup from 'yup';
import { ContributionType, ContributionTypesInfos } from '../../types';
import AppText from '../atomic/AppText';
import { CustomModule } from '../../entities/CustomModule';
import { t } from '../../services/translations';
import Form from '../atomic/formik/Form';
import { useMutation } from '@tanstack/react-query';
import { postCategory } from '../../services/api/category.api';
import useLoggedUser from '../../hooks/useLoggedUser';
import { setLoading } from '../../store/action';
import { useDispatch } from 'react-redux';
import SubmitButton from '../atomic/formik/SubmitButton';
import TextField from '../atomic/formik/TextField';
import { categoriesColors } from '../../screens/categories/CreateCategoryScreen';
import ColorPickerField from '../atomic/formik/ColorPickerField';

export type CreateCategoryFormProps = {
  contributionType?: ContributionType;
  customModule?: CustomModule;
  onSuccess?: () => void;
};

type FormValues = {
  name: string;
  color: string;
};

const schema = Yup.object({
  name: Yup.string().min(1, t('field_required')).required(t('field_required')),
  color: Yup.string()
    .min(1, t('field_required'))
    .test('is-color', t('field_invalid'), function (value) {
      return CSS.supports('color', value);
    })
    .required(t('field_required')),
});

export default function CreateCategoryForm({ contributionType, customModule, onSuccess }: CreateCategoryFormProps) {
  const loggedUser = useLoggedUser();
  const dispatch = useDispatch();
  const [resetKey, setResetKey] = useState(0);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async (values: FormValues) => {
      await postCategory(
        loggedUser.entreprise?.id!,
        {
          type: contributionType ? ContributionTypesInfos[contributionType].key : null,
          customModuleId: customModule?.id,
          name: values.name,
          color: values.color,
        },
        loggedUser.token!
      );
    },
    onSuccess: () => {
      onSuccess?.();
      setResetKey(resetKey + 1);
    },
  });

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [dispatch, isLoading]);

  return (
    <Form
      key={resetKey}
      initialValues={{
        name: '',
        color: '',
      }}
      onSubmit={mutateAsync as any}
      validationSchema={schema}
      validateOnMount
    >
      <View>
        {customModule && <AppText style={styles.title}>{customModule.title}</AppText>}
        {contributionType && <AppText style={styles.title}>{t(ContributionTypesInfos[contributionType].key)}</AppText>}
        <TextField name="name" mandatory title={t('category_name') + '*'} />
        <ColorPickerField name="color" title={t('category_color')} colors={categoriesColors} required />
        <SubmitButton text={t('creating_the_category')} />
      </View>
    </Form>
  );
}

const styles = StyleSheet.create({
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    marginVertical: 18,
  },
});
