import axios from 'axios';
import { apiUrlBase } from '../../../configuration';
import { headers } from './base';
import { addPhotoToFormData, getSpreadingFormData } from './formData.api';
import { PickPhotoOrDocumentResult } from '../utils';
import { GetContributionsMode } from './getContributionsMode';
import { CompanyNewPostData } from '../../models/Contribution';
import { objectToFormData } from '../formUtil';
import { UserSaved } from '../storage';
import { MActuPostDetails } from '../../entities/MActuPostDetails';
import { CompanyNewsUpload } from '../../models/CompanyNews';
import { MActuFavoriteUpdate } from '../../entities/MActuFavoriteUpdate';
import { MActuInterestUpdate } from '../../entities/MActuInterestUpdate';
import { User } from '../../entities/User';
import { Paginated } from '../../entities/Paginated';

export async function removeCompanyNews(user: UserSaved, id: number) {
  return axios
    .put(
      apiUrlBase + 'MActu/sup/' + id,
      {},
      {
        headers: headers(user.token),
      }
    )
    .then((response) => {
      return response;
    });
}

export async function setApproveNews(user: UserSaved, newsId: number) {
  return axios
    .put(
      apiUrlBase + 'MActu/dovalider/' + newsId,
      {},
      {
        headers: headers(user.token),
      }
    )
    .then((response) => {
      return response;
    });
}

export function getCompanyNewsDetail(user: UserSaved, id?: number) {
  return axios
    .get<MActuPostDetails>(`${apiUrlBase}MActu/detail/${id}`, { headers: headers(user.token) })
    .then((response) => {
      return response.data;
    });
}

export function getCompanyNews(user: UserSaved, getContributionsMode: GetContributionsMode) {
  let url = '';
  switch (getContributionsMode) {
    case GetContributionsMode.all:
      url = apiUrlBase + 'MActu/user/' + user.id + '/paginated';
      break;
    case GetContributionsMode.toApprove:
      url = apiUrlBase + 'MActu/avalider/' + user.entrepriseId + '/paginated';
      break;
    default:
    case GetContributionsMode.approved:
      url = apiUrlBase + 'MActu/valider/' + user.entrepriseId + '/paginated';
      break;
  }
  return axios
    .get(url, {
      headers: headers(user.token),
    })
    .then((response) => response.data);
}

function CreateFormDataCompanyNews(photo: PickPhotoOrDocumentResult, body: any) {
  const data = objectToFormData(body);

  if (photo !== undefined && photo !== null && photo.fileName !== null) {
    addPhotoToFormData(photo, data);
  }

  return data;
}

export const postCompanyNews = (
  user: UserSaved,
  data: CompanyNewsUpload,
  photo: PickPhotoOrDocumentResult | undefined
) => {
  const body = objectToFormData({ ...data, visibleZone: false });
  if (photo !== undefined && photo !== null && photo.fileName !== null) {
    addPhotoToFormData(photo, body);
  }

  return axios.post(`${apiUrlBase}mactu/mactu`, body, { headers: headers(user.token, 'multipart/form-data') });
};

export const putCompanyNews = (
  user: UserSaved,
  id: number,
  data: CompanyNewsUpload,
  photo: PickPhotoOrDocumentResult | undefined
) => {
  const body = objectToFormData({ ...data, visibleZone: false });

  if (photo !== undefined && photo !== null && photo.fileName !== null) {
    addPhotoToFormData(photo, body);
  }

  return axios.put(`${apiUrlBase}mactu/mactu/update/${id}`, body, {
    headers: headers(user.token, 'multipart/form-data'),
  });
};

export function publishNews(data: CompanyNewPostData, photo: PickPhotoOrDocumentResult) {
  const body: any = {
    userId: data.user.id,
    entrepriseId: data.user.entreprise.id,
    titre: data.title,
    texte: data.description,
    visibleZone: false,
    videoUrl: data.videoUrl,
    highlightedDays: data.highlightedDays,
    traduction: JSON.stringify(data.translations),
    DateDebutPublication: undefined,
    DateFinPublication: undefined,
    visibleStartDate: data.visibleStartDate,
    visibleEndDate: data.visibleEndDate,
    translations: data.translations,
    notifActivated: data.notifActivated,
    emailActivated: data.emailActivated,
    ...getSpreadingFormData(data),
  };

  if (data.visibleStartDate !== undefined) {
    body.DateDebutPublication = data.visibleStartDate;

    if (data.visibleEndDate !== undefined) {
      body.DateFinPublication = data.visibleEndDate;
    }
  }

  try {
    return axios.post(apiUrlBase + 'mactu/mactu', CreateFormDataCompanyNews(photo, body), {
      headers: headers(data.user.token, 'multipart/form-data'),
    });
  } catch (e) {}
}

export function updateNews(id: number, data: CompanyNewPostData, photo: PickPhotoOrDocumentResult) {
  const body: any = {
    userId: data.user.id,
    entrepriseId: data.user.entreprise.id,
    titre: data.title,
    texte: data.description,
    visibleZone: false,
    videoUrl: data.videoUrl,
    highlightedDays: data.highlightedDays,
    traduction: JSON.stringify(data.translations),
    DateDebutPublication: undefined,
    DateFinPublication: undefined,
    visibleStartDate: data.visibleStartDate,
    visibleEndDate: data.visibleEndDate,
    translations: data.translations,
    notifActivated: data.notifActivated,
    emailActivated: data.emailActivated,
    ...getSpreadingFormData(data),
  };
  body.DateDebutPublication = data.visibleStartDate;
  body.DateFinPublication = data.visibleEndDate;

  return axios.put(apiUrlBase + 'MActu/mactu/update/' + id, CreateFormDataCompanyNews(photo, body), {
    headers: headers(data.user.token, 'multipart/form-data'),
  });
}

export const getNewsViewCount = (user: UserSaved, id: number) =>
  axios
    .get(`${apiUrlBase}MActuUserViews/${id}`, {
      headers: headers(user.token),
    })
    .then((d) => Number(d.data))
    .catch(() => 0);

export async function listNewsInterestedUsers(
  user: UserSaved,
  id: number,
  cursor: string | null
): Promise<Paginated<User, string>> {
  return axios
    .get(`${apiUrlBase}MActu/${id}/interested`, {
      headers: headers(user.token),
      params: { cursor },
    })
    .then((d) => d.data)
    .catch(() => []);
}

export async function setInterestedNews(user: UserSaved, id: number, body: MActuInterestUpdate) {
  return axios
    .put(`${apiUrlBase}MActu/${id}/interested`, body, {
      headers: headers(user.token),
    })
    .then((response) => {
      return response;
    });
}

export async function setFavoriteNews(user: UserSaved, mActuId: number, body: MActuFavoriteUpdate) {
  return axios
    .put(`${apiUrlBase}MActu/${mActuId}/favorite`, body, {
      headers: headers(user.token),
    })
    .then((response) => {
      return response;
    });
}
