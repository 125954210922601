import React from 'react';
import { ContributionsMainScreen } from './ContributionsMainScreen';
import {
  ContributionItemRowBigPicture,
  ContributionItemRowCarPool,
  ContributionItemRowCompanyNews,
  ContributionItemSquare,
} from '../../../components/atomic/ContributionItemSquare';
import { ContributionType, ContributionTypesInfos, IntToContributionType } from '../../../types';
import { extractContributionFromResponse, resolveContributionType } from '../../../services/api/contributions';
import { isMobile } from '../../../services/utils';
import { GetContributionsMode, IntToGetContributionsMode } from '../../../services/api/getContributionsMode';
import { FloatingButtonsNews } from './FloatingButtons';
import { ScreenNames } from '../../../ScreenNames';
import { View } from 'react-native';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import ChatbotButton from '../../../components/molecules/ChatbotButton';
import useLoggedUser from '../../../hooks/useLoggedUser';

export function ContributionListScreen(props: { navigation: NavigationProp<ParamListBase>; route: any }) {
  const contributionMode = IntToGetContributionsMode(props.route.params.getContributionsMode);
  const contributionModeSecondView = IntToGetContributionsMode(props.route.params.getContributionsModeSecondView);
  const isAdminView = props.route.params?.isAdminView;
  const hideCategory = props.route.params?.hideCategory;
  const isSignaled = props.route.params?.isSignaled;
  const user = useLoggedUser();
  const contributionType: ContributionType = IntToContributionType(props.route.params.contributionType);

  function _getRenderItem(
    item: any,
    user: any,
    contributionType: ContributionType,
    contributionMode: GetContributionsMode,
    onPress: (item: any) => void
  ) {
    if (item.isEmpty) {
      return <View style={{ width: 300 }}></View>;
    }
    switch (contributionType) {
      case ContributionType.CARPOOL:
        return (
          <ContributionItemRowCarPool
            item={item}
            getContributionsMode={contributionMode}
            navigation={props.navigation}
            user={user}
            onPress={onPress}
            contributionType={contributionType}
          />
        );
      case ContributionType.EVENT:
      case ContributionType.FORMATION:
      case ContributionType.LOST_PROPERTY:
      case ContributionType.ACTIVITY:
      case ContributionType.SELL:
      case ContributionType.MUTUAL_AID:
      case ContributionType.SURVEY:
      case ContributionType.SUGGESTION:
      case ContributionType.DOCUMENT:
        return ContributionItemSquare(item, contributionMode, props.navigation, user, contributionType, onPress);
      case ContributionType.ALL:
        return (
          <ContributionItemRowBigPicture
            item={item}
            getContributionsMode={contributionMode}
            navigation={props.navigation}
            user={user}
            contributionType={contributionType}
            isAdminView={isAdminView}
            onPress={onPress}
            style={{ width: '100%' }}
          />
        );
      case ContributionType.COMPANY_NEWS:
        return (
          <ContributionItemRowCompanyNews
            item={item}
            navigation={props.navigation}
            user={user}
            isAdminView={isAdminView}
            onPress={onPress}
            contributionType={contributionType}
            style={{ width: '100%' }}
          />
        );
      default:
        return <></>;
    }
  }

  function _getNumColumns(contributionType: any) {
    if (!isMobile()) {
      if (contributionType === ContributionType.CARPOOL) return 1;
      return undefined;
    }
    switch (contributionType) {
      case ContributionType.LOST_PROPERTY:
      case ContributionType.ACTIVITY:
      case ContributionType.SELL:
      case ContributionType.MUTUAL_AID:
      case ContributionType.SURVEY:
      case ContributionType.SUGGESTION:
      case ContributionType.EVENT:
      case ContributionType.FORMATION:
        return 2;
      default:
      case ContributionType.DOCUMENT:
      case ContributionType.CARPOOL:
      case ContributionType.COMPANY_NEWS:
      case ContributionType.ALL:
        return 1;
    }
  }

  return (
    <>
      <ContributionsMainScreen
        contributionType={contributionType}
        contributionMode={contributionMode}
        contributionModeSecondView={contributionModeSecondView}
        numColumns={_getNumColumns(contributionType, isMobile())}
        isAdminView={isAdminView}
        hideCategory={hideCategory}
        isSignaled={isSignaled}
        navigation={props.navigation}
        getRenderItem={(item, contributionMode, onPress) =>
          _getRenderItem(item, user, contributionType, contributionMode, onPress)
        }
        onPress={(item) => {
          let _contributionType = contributionType;
          let _id = item.id;
          if (_contributionType == ContributionType.ALL) {
            _contributionType = resolveContributionType(item);
            _id = extractContributionFromResponse(item, _contributionType).id;
          }

          props.navigation.push(ScreenNames.LoggedInDrawerNavigation, {
            screen: ContributionTypesInfos[contributionType].detailScreen ?? ScreenNames.ContributionItemDetailScreen,
            params: {
              id: _id,
              contributionType: _contributionType,
              isNotif: false,
              isAdminView,
            },
          });
        }}
      />
      {contributionType === ContributionType.COMPANY_NEWS && (
        <>
          <FloatingButtonsNews
            onPress={() => {
              props.navigation.navigate(ScreenNames.CreateCompanyNews);
            }}
          />
        </>
      )}
      <ChatbotButton />
    </>
  );
}
