import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ContributionCustomModuleCreate } from '../../../../entities/ContributionCustomModuleCreate';
import { createCustomModuleContributions } from '../../../../services/api/custom.module.api';
import { alertInfo } from '../../../../services/utils';
import { setContributionsListNeedsRefresh } from '../../../../store/action';
import { t } from '../../../../services/translations';
import Form from '../../../../components/atomic/formik/Form';
import CustomModuleContributionFormFields, { schema } from './CustomModuleContributionFormFields';
import useLoggedUser from '../../../../hooks/useLoggedUser';

export type CreateCustomModuleContributionScreenProps = {
  route: {
    params: {
      moduleIdentifier: string;
    };
  };
};

export default function CreateCustomModuleContributionScreen({ route }: CreateCustomModuleContributionScreenProps) {
  const user = useLoggedUser();
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();
  const moduleIdentifier = route.params.moduleIdentifier;

  const { mutateAsync, isError } = useMutation({
    mutationFn: async (values: ContributionCustomModuleCreate) => {
      await createCustomModuleContributions(user, moduleIdentifier, values);
      dispatch(setContributionsListNeedsRefresh(true));
      navigation.goBack();
    },
  });

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  return (
    <Form
      initialValues={{
        commentsActivated: true,
        notifActivated: true,
        emailActivated: false,
      }}
      onSubmit={mutateAsync as any}
      validationSchema={schema}
      validateOnMount
    >
      <CustomModuleContributionFormFields customModuleIdentifier={moduleIdentifier} />
    </Form>
  );
}
