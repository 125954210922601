import React from 'react';
import { ImageSourcePropType } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as Yup from 'yup';
import ContributionAuthorizationsField from '../../../../components/atomic/formik/ContributionAuthorizationsField';
import ContributionSpreadingField, {
  contributionSpreadingFieldValidators,
} from '../../../../components/atomic/formik/ContributionSpreadingField';
import PhotoField from '../../../../components/atomic/formik/PhotoField';
import RichTextField from '../../../../components/atomic/formik/RichTextField';
import SubmitButton from '../../../../components/atomic/formik/SubmitButton';
import TextField from '../../../../components/atomic/formik/TextField';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { t } from '../../../../services/translations';
import CategoryField from '../../../../components/atomic/formik/CategoryField';

export const schema = Yup.object({
  title: Yup.string().min(1).required(t('field_required')),
  description: Yup.string().min(1).required(t('field_required')),
  image: Yup.mixed(),
  commentsActivated: Yup.boolean().required(t('field_required')),
  notifActivated: Yup.boolean().required(t('field_required')),
  emailActivated: Yup.boolean().required(t('field_required')),
  ...contributionSpreadingFieldValidators,
});

export type CustomModuleContributionFormFieldsProps = {
  customModuleIdentifier: string | undefined;
  isEditing?: boolean | undefined;
  defaultPhoto?: ImageSourcePropType | undefined;
};

export default function CustomModuleContributionFormFields({
  customModuleIdentifier,
  isEditing,
  defaultPhoto,
}: CustomModuleContributionFormFieldsProps) {
  return (
    <AppScreen>
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps="never"
        style={{ padding: 10 }}
        keyboardDismissMode="on-drag"
      >
        <PhotoField name="image" defaultPhoto={defaultPhoto} allowPdf />
        <TextField name="title" mandatory required title={t('title')} />
        <RichTextField name="description" title={t('description')} required />
        <ContributionSpreadingField filterByUserRights subAdminCanShareWithEveryone />
        <CategoryField name="categoryId" title={t('categories')} customModuleIdentifier={customModuleIdentifier} />
        <ContributionAuthorizationsField />
        <SubmitButton text={!isEditing ? t('put') : t('update_button')} />
      </KeyboardAwareScrollView>
    </AppScreen>
  );
}
